<template>
  <!-- position must be relative to start a new containing block so the background div can be 100% height -->
  <!-- only if using percentage-->
  <div>
    <div
      style="
        position: fixed;
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        background: white;
      "
    >
      <div
        :class="{ hearts: !highFives, hands: highFives }"
        style="
          position: relative;
          top: -100vh;
          left: -100vw;
          height: 300vh;
          width: 300vw;
          transform: rotate(-20deg) scale(2);
        "
      ></div>
    </div>
    <div id="interviews">
      <Alert />
      <Spinner />
      <nav class="navbar navbar-default navbar-fixed-top" style="z-index: 1050">
        <div class="container">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="/">
              <img
                :src="staticaddr + '/ivgcs/img/logo-original.png'"
                alt="InitialView"
                style="width: 218px; padding-top: 17px"
              />
            </a>
          </div>
          <div id="navbar" class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right waitToLoad">
              <li>
                <a href="#list">{{ orgName }}</a>
              </li>
              <!--
							<li data-toggle="tooltip" data-placement="bottom" title="Settings">
								<a href='#settings'>
								<span class='glyphicon glyphicon-cog'></span>
								<span class='sr-only'>Settings</span>
								</a>
							</li>
							-->
              <li data-toggle="tooltip" data-placement="bottom" title="Logout">
                <a href="#logout" v-on:click.prevent="user_logout()">Log Out</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- end of regular navbar -->
      <!-- Must have bottom margin for the footer... -->
      <div class="container" style="width: 400px" v-if="apiDown">
        <center>
          <h3>System upgrade in progress, please try again in 5 minutes.</h3>
        </center>
      </div>
      <div
        class="container-fluid"
        v-show="current_view == 'list'"
        id="pool-of-love-container"
        v-if="!apiDown"
      >
        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li
            role="presentation"
            :class="{ active: !highFives }"
            class="hearts"
          >
            <a
              aria-controls="pool-of-love"
              role="tab"
              data-toggle="tab"
              v-on:click="highFives = false"
            >
              <img height="20px" src="../assets/heart.svg" /> Pool of Love
              <img height="20px" src="../assets/heart.svg" />
            </a>
          </li>
          <li
            role="presentation"
            :class="{ active: highFives }"
            class="hands"
            v-show="highFiveCount"
            style="margin-left: 20px"
          >
            <a
              aria-controls="high-fives"
              role="tab"
              data-toggle="tab"
              v-on:click="highFives = true"
            >
              <img height="20px" src="../assets/hand_dark.svg" /> High Fives
              <img height="20px" src="../assets/hand_dark.svg" />
            </a>
          </li>
        </ul>
        <div class="row" style="padding: 0 15px">
          <!-- This row helps with the layout -->
          <div class="col-xs-2">
            <h4>Filter by</h4>
            <div
              class="panel-group"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
              style="max-height: calc(100vh - 280px); overflow-y: auto"
            >
              <div class="panel panel-default">
                <div
                  class="panel-heading collapsed"
                  role="tab"
                  id="headingOne"
                  data-toggle="collapse"
                  href="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <a role="button"> Self-reported average grade </a>
                </div>
                <div
                  id="collapseOne"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingOne"
                >
                  <div class="panel-body">
                    <!--
										<div >
											<span class='btn btn-link' v-on:click="Object.values(filterObject.grades).forEach(grade=>grade.show = true)">select all</span> |
											<span class='btn btn-link' v-on:click="Object.values(filterObject.grades).forEach(grade=>grade.show = false)">select none</span>
										</div>
										<div class="alert alert-warning" role="alert" v-show='Object.values(filterObject.grades).every(grade=>!grade.show)' >Please select a grade tier</div>
										-->
                    <div
                      class="checkbox"
                      v-for="grade in filterObject.grades"
                      v-bind:key="grade.label"
                    >
                      <label>
                        <input type="checkbox" v-model="grade.show" />
                        {{ grade.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div
                  class="panel-heading collapsed"
                  role="tab"
                  id="headingTwo"
                  data-toggle="collapse"
                  href="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <a role="button"> Academic interests </a>
                </div>
                <div
                  id="collapseTwo"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                >
                  <div class="panel-body">
                    <!--
										<div >
											<span class='btn btn-link' v-on:click="Object.values(filterObject.academicInterests).forEach(interest=>interest.show = true)">select all</span> |
											<span class='btn btn-link' v-on:click="Object.values(filterObject.academicInterests).forEach(interest=>interest.show = false)">select none</span>
										</div>
										<div class="alert alert-warning" role="alert" v-show='Object.values(filterObject.academicInterests).every(interest=>!interest.show)' >Please select an interest</div>
										-->
                    <div
                      class="checkbox"
                      v-for="interest in filterObject.academicInterests"
                      v-bind:key="interest.label"
                    >
                      <label>
                        <input type="checkbox" v-model="interest.show" />
                        {{ interest.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div
                  class="panel-heading collapsed"
                  role="tab"
                  id="headingThree"
                  data-toggle="collapse"
                  href="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <a role="button"> College size </a>
                </div>
                <div
                  id="collapseThree"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree"
                >
                  <div class="panel-body">
                    <!--
										<div >
											<span class='btn btn-link' v-on:click="Object.values(filterObject.collegeSize).forEach(size=>size.show = true)">select all</span> |
											<span class='btn btn-link' v-on:click="Object.values(filterObject.collegeSize).forEach(size=>size.show = false)">select none</span>
										</div>
										<div class="alert alert-warning" role="alert" v-show='Object.values(filterObject.collegeSize).every(size=>!size.show)' >Please select a size</div>
										-->
                    <div
                      class="checkbox"
                      v-for="size in filterObject.collegeSize"
                      v-bind:key="size.label"
                    >
                      <label>
                        <input type="checkbox" v-model="size.show" />
                        {{ size.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div
                  class="panel-heading collapsed"
                  role="tab"
                  id="headingFour"
                  data-toggle="collapse"
                  href="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <a role="button"> Geographic preference </a>
                </div>
                <div
                  id="collapseFour"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingFour"
                >
                  <div class="panel-body">
                    <!--
										<div >
											<span class='btn btn-link' v-on:click="Object.values(filterObject.geographicPreference).forEach(region=>region.show = true)">select all</span> |
											<span class='btn btn-link' v-on:click="Object.values(filterObject.geographicPreference).forEach(region=>region.show = false)">select none</span>
										</div>
										<div class="alert alert-warning" role="alert" v-show='Object.values(filterObject.geographicPreference).every(region=>!region.show)' >Please select a region</div>
										-->
                    <div
                      class="checkbox"
                      v-for="region in filterObject.geographicPreference"
                      v-bind:key="region.label"
                    >
                      <label>
                        <input type="checkbox" v-model="region.show" />
                        {{ region.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div
                  class="panel-heading collapsed"
                  role="tab"
                  id="headingFive"
                  data-toggle="collapse"
                  href="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <a role="button"> Gender identity </a>
                </div>
                <div
                  id="collapseFive"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingFive"
                >
                  <div class="panel-body">
                    <div
                      class="checkbox"
                      v-for="gender in filterObject.genders"
                      v-bind:key="gender.label"
                    >
                      <label>
                        <input type="checkbox" v-model="gender.show" />
                        {{ gender.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div
                  class="panel-heading collapsed"
                  role="tab"
                  id="headingSix"
                  data-toggle="collapse"
                  href="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  <a role="button"> Date of college entry </a>
                </div>
                <div
                  id="collapseSix"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingSix"
                >
                  <div class="panel-body">
                    <div
                      class="checkbox"
                      v-for="entryDate in filterObject.entryDates"
                      v-bind:key="entryDate.label"
                    >
                      <label>
                        <input type="checkbox" v-model="entryDate.show" />
                        {{ entryDate.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div role="tabpanel" aria-labelledby="headingSix">
                  <div class="panel-body">
                    <div
                      class="checkbox"
                      v-for="demographic in filterObject.demographics"
                      v-bind:key="demographic.label"
                    >
                      <label>
                        <input type="checkbox" v-model="demographic.show" />
                        {{ demographic.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-10">
            <div class="row" style="text-align: right">
              <button class="btn btn-orange" v-on:click="reset">
                Reset Filters
              </button>
              <button
                class="btn btn-orange"
                v-on:click="showHighFiveResponseModal"
                v-show="highFives && orgHighFiveMessageRecorded"
              >
                View High Five Response Video
              </button>
              <button class="btn btn-orange" v-on:click="exportStudents">
                Export Students to CSV
              </button>
            </div>
            <div class="row" id="filter-displays" v-show="showFilters">
              <span style="margin-right: 10px; font-weight: bold"
                >Filters:
              </span>
              <!-- Could probably use a for loop here or something... -->
              <span
                class="label label-default"
                v-for="grade in filterObject.grades"
                v-bind:key="'grade' + grade.label"
                v-show="
                  !Object.values(filterObject.grades).every(
                    (grade) => grade.show
                  ) && grade.show
                "
                >Grade: {{ grade.label }}
                <span v-on:click="grade.show = false">&#10006;</span></span
              >
              <span
                class="label label-default"
                v-for="interest in filterObject.academicInterests"
                v-bind:key="'interest' + interest.label"
                v-show="
                  !Object.values(filterObject.academicInterests).every(
                    (interest) => interest.show
                  ) && interest.show
                "
                >Interest: {{ interest.label }}
                <span
                  v-on:click="interest.show = false"
                  style="padding: 5px; cursor: pointer"
                  >&#10006;</span
                ></span
              >
              <span
                class="label label-default"
                v-for="size in filterObject.collegeSize"
                v-bind:key="'size' + size.label"
                v-show="
                  !Object.values(filterObject.collegeSize).every(
                    (size) => size.show
                  ) && size.show
                "
                >Size: {{ size.label }}
                <span
                  v-on:click="size.show = false"
                  style="padding: 5px; cursor: pointer"
                  >&#10006;</span
                ></span
              >
              <span
                class="label label-default"
                v-for="region in filterObject.geographicPreference"
                v-bind:key="'region' + region.label"
                v-show="
                  !Object.values(filterObject.geographicPreference).every(
                    (region) => region.show
                  ) && region.show
                "
                >Region: {{ region.label }}
                <span
                  v-on:click="region.show = false"
                  style="padding: 5px; cursor: pointer"
                  >&#10006;</span
                ></span
              >
              <span
                class="label label-default"
                v-for="gender in filterObject.genders"
                v-bind:key="'gender' + gender.label"
                v-show="
                  !Object.values(filterObject.genders).every(
                    (gender) => gender.show
                  ) && gender.show
                "
                >Gender: {{ gender.label }}
                <span
                  v-on:click="gender.show = false"
                  style="padding: 5px; cursor: pointer"
                  >&#10006;</span
                ></span
              >
              <span
                class="label label-default"
                v-for="entryDate in filterObject.entryDates"
                v-bind:key="'entry' + entryDate.label"
                v-show="
                  !Object.values(filterObject.entryDates).every(
                    (entryDate) => entryDate.show
                  ) && entryDate.show
                "
                >Entry Date: {{ entryDate.label }}
                <span
                  v-on:click="entryDate.show = false"
                  style="padding: 5px; cursor: pointer"
                  >&#10006;</span
                ></span
              >
            </div>
            <div class="row" style="background-color: #fff">
              <div class="col-xs-12">
                <table class="table" id="listhead">
                  <thead>
                    <tr>
                      <th
                        data-ref="playIcon"
                        style="min-width: 50px; vertical-align: top"
                        v-if="!highFives"
                      >
                        <span
                          v-show="
                            interviews.length - highFiveCount ==
                            filteredInterviews.length
                          "
                        >
                          {{ interviews.length - highFiveCount }} Records
                        </span>
                        <span
                          v-show="
                            interviews.length - highFiveCount !=
                            filteredInterviews.length
                          "
                        >
                          Filtered {{ filteredInterviews.length }} of
                          {{ interviews.length - highFiveCount }}
                        </span>
                      </th>
                      <th
                        data-ref="playIcon"
                        style="min-width: 50px; vertical-align: top"
                        v-if="highFives"
                      >
                        <span
                          v-show="highFiveCount == filteredInterviews.length"
                        >
                          {{ highFiveCount }} Records
                        </span>
                        <span
                          v-show="highFiveCount != filteredInterviews.length"
                        >
                          Filtered {{ filteredInterviews.length }} of
                          {{ highFiveCount }}
                        </span>
                      </th>
                      <th
                        data-ref="dateReceived"
                        style="min-width: 70px"
                        v-bind:class="{
                          'sorted-column': orderByColumn == 'dateReceived',
                        }"
                      >
                        <div v-on:click="orderByColumn = 'dateReceived'">
                          Date Received
                          <span
                            v-if="orderByColumn == 'dateReceived'"
                            v-on:click="sortOrder = sortOrder * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder > 0,
                                'glyphicon-menu-down': sortOrder < 0,
                              }"
                            ></i>
                          </span>
                          <span
                            v-if="orderByColumn2 == 'dateReceived'"
                            v-on:click="sortOrder2 = sortOrder2 * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder2 > 0,
                                'glyphicon-menu-down': sortOrder2 < 0,
                              }"
                            ></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="filterObject.dateReceived"
                        />
                      </th>
                      <th
                        data-ref="lastName"
                        style="min-width: 110px"
                        v-bind:class="{
                          'sorted-column': orderByColumn == 'lastName',
                        }"
                      >
                        <div v-on:click="orderByColumn = 'lastName'">
                          Last Name
                          <span
                            v-if="orderByColumn == 'lastName'"
                            v-on:click="sortOrder = sortOrder * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder > 0,
                                'glyphicon-menu-down': sortOrder < 0,
                              }"
                            ></i>
                          </span>
                          <span
                            v-if="orderByColumn2 == 'lastName'"
                            v-on:click="sortOrder2 = sortOrder2 * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder2 > 0,
                                'glyphicon-menu-down': sortOrder2 < 0,
                              }"
                            ></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="filterObject.lastName"
                        />
                      </th>
                      <th
                        data-ref="firstName"
                        style="min-width: 110px"
                        v-bind:class="{
                          'sorted-column': orderByColumn == 'firstName',
                        }"
                      >
                        <div v-on:click="orderByColumn = 'firstName'">
                          First Name
                          <span
                            v-if="orderByColumn == 'firstName'"
                            v-on:click="sortOrder = sortOrder * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder > 0,
                                'glyphicon-menu-down': sortOrder < 0,
                              }"
                            ></i>
                          </span>
                          <span
                            v-if="orderByColumn2 == 'firstName'"
                            v-on:click="sortOrder2 = sortOrder2 * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder2 > 0,
                                'glyphicon-menu-down': sortOrder2 < 0,
                              }"
                            ></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="filterObject.firstName"
                        />
                      </th>
                      <th
                        data-ref="email"
                        style="min-width: 200px"
                        v-bind:class="{
                          'sorted-column': orderByColumn == 'email',
                        }"
                      >
                        <div v-on:click="orderByColumn = 'email'">
                          Email
                          <span
                            v-if="orderByColumn == 'email'"
                            v-on:click="sortOrder = sortOrder * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder > 0,
                                'glyphicon-menu-down': sortOrder < 0,
                              }"
                            ></i>
                          </span>
                          <span
                            v-if="orderByColumn2 == 'email'"
                            v-on:click="sortOrder2 = sortOrder2 * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder2 > 0,
                                'glyphicon-menu-down': sortOrder2 < 0,
                              }"
                            ></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="filterObject.email"
                        />
                      </th>
                      <th
                        data-ref="cell"
                        style="min-width: 200px"
                        v-bind:class="{
                          'sorted-column': orderByColumn == 'cell',
                        }"
                      >
                        <div v-on:click="orderByColumn = 'cell'">
                          Cellphone
                          <span
                            v-if="orderByColumn == 'cell'"
                            v-on:click="sortOrder = sortOrder * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder > 0,
                                'glyphicon-menu-down': sortOrder < 0,
                              }"
                            ></i>
                          </span>
                          <span
                            v-if="orderByColumn2 == 'cell'"
                            v-on:click="sortOrder2 = sortOrder2 * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder2 > 0,
                                'glyphicon-menu-down': sortOrder2 < 0,
                              }"
                            ></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="filterObject.citystate"
                        />
                      </th>
                      <!--
											<th data-ref="cell" style="min-width:200px;" v-bind:class="{'sorted-column': orderByColumn == 'citystate'}">
												<div v-on:click="orderByColumn = 'citystate'">
													City
													<span v-if="orderByColumn == 'citystate'" v-on:click="sortOrder = sortOrder * -1">
													<i class="glyphicon" v-bind:class="{'glyphicon-menu-up': sortOrder > 0, 'glyphicon-menu-down': sortOrder < 0}"></i>
													</span>
													<span v-if="orderByColumn2 == 'citystate'" v-on:click="sortOrder2 = sortOrder2 * -1">
													<i class="glyphicon" v-bind:class="{'glyphicon-menu-up': sortOrder2 > 0, 'glyphicon-menu-down': sortOrder2 < 0}"></i>
													</span>
												</div>
												<input type="text" class="form-control" v-model="filterObject.cell">
											</th>
											-->
                      <th
                        data-ref="response"
                        style="min-width: 20px; vertical-align: top"
                        v-bind:class="{
                          'sorted-column': orderByColumn == 'response',
                        }"
                      >
                        <div v-on:click="orderByColumn = 'response'">
                          Response Status
                          <span
                            v-if="orderByColumn == 'response'"
                            v-on:click="sortOrder = sortOrder * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder > 0,
                                'glyphicon-menu-down': sortOrder < 0,
                              }"
                            ></i>
                          </span>
                          <span
                            v-if="orderByColumn2 == 'response'"
                            v-on:click="sortOrder2 = sortOrder2 * -1"
                          >
                            <i
                              class="glyphicon"
                              v-bind:class="{
                                'glyphicon-menu-up': sortOrder2 > 0,
                                'glyphicon-menu-down': sortOrder2 < 0,
                              }"
                            ></i>
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div class="container" id="list-view" style="top: 67px">
                  <div v-on:scroll="handleScroll" id="scrollable">
                    <div
                      v-show="highFives && !orgHighFiveMessageRecorded"
                      id="highFiveLock"
                    >
                      <button
                        class="btn btn-orange"
                        v-on:click="showHighFiveEpicModal"
                      >
                        Unlock your
                        {{ highFiveCount ? highFiveCount + " " : "" }}High Fives
                        by recording and sending a response video! <br />Please
                        note the video will go to all students who gave you High
                        Fives.
                      </button>
                    </div>
                    <div v-bind:style="itemContainerStyle">
                      <table
                        class="table table-striped"
                        v-bind:style="itemsStyle"
                      >
                        <tbody>
                          <tr
                            v-for="(
                              propsitem, propsitemIndex
                            ) in visibleInterviews"
                            v-bind:key="'shown' + propsitemIndex"
                            v-show="
                              !highFives ||
                              (highFives && orgHighFiveMessageRecorded)
                            "
                            v-on:click="showStudentInformationModal(propsitem)"
                          >
                            <td v-bind:style="{ width: colWidths.playIcon }">
                              <img
                                :src="
                                  staticaddr + '/ivgcs/img/epic-play-icon.png'
                                "
                              />
                            </td>
                            <td
                              v-bind:style="{ width: colWidths.dateReceived }"
                            >
                              {{ propsitem.dateReceived }}
                            </td>
                            <td v-bind:style="{ width: colWidths.lastName }">
                              {{ propsitem.lastName }}
                            </td>
                            <td v-bind:style="{ width: colWidths.firstName }">
                              {{ propsitem.firstName }}
                            </td>
                            <td
                              v-show="propsitem.locked"
                              colspan="2"
                              v-bind:style="{
                                width:
                                  parseInt(
                                    colWidths.email.substr(
                                      0,
                                      colWidths.email.length - 2
                                    )
                                  ) +
                                  parseInt(
                                    colWidths.cell.substr(
                                      0,
                                      colWidths.cell.length - 2
                                    )
                                  ) +
                                  'px',
                              }"
                              class="text-center"
                            >
                              <img height="20px" src="../assets/locked.svg" />
                              <i> Record response to unlock contact info </i>
                              <img
                                width="30px"
                                :src="
                                  staticaddr + '/ivgcs/img/epic-play-icon.png'
                                "
                              />
                            </td>
                            <td
                              v-show="!propsitem.locked"
                              v-bind:style="{ width: colWidths.email }"
                              v-bind:title="propsitem.email"
                            >
                              {{ propsitem.email }}
                            </td>
                            <td
                              v-show="!propsitem.locked"
                              v-bind:style="{ width: colWidths.cell }"
                              v-bind:title="propsitem.cell"
                            >
                              {{ propsitem.cell
                              }}<!--<span v-show="propsitem.locked"><img height="20px" src="../assets/locked.svg"> <i>Record response to unlock</i> <img width="30px" :src="staticaddr + '/ivgcs/img/epic-play-icon.png'" /></span>-->
                            </td>
                            <!--
														<td v-bind:style="{'width': colWidths.citystate}" v-bind:title="propsitem.citystate">
															{{propsitem.hsCity}}, {{propsitem.hsState}}
														</td>
														-->
                            <td
                              v-bind:style="{ width: colWidths.response }"
                              v-bind:title="propsitem.response"
                            >
                              {{
                                propsitem.highFive
                                  ? propsitem.response
                                    ? "Individual Follow-up Completed"
                                    : "Pursue Individual Follow-up"
                                  : propsitem.response
                                  ? "Responded"
                                  : "Not Responded"
                              }}
                            </td>
                          </tr>
                          <tr
                            v-for="(
                              propsitem, propsitemIndex
                            ) in visibleInterviews"
                            v-bind:key="'hidden' + propsitemIndex"
                            v-show="highFives && !orgHighFiveMessageRecorded"
                          >
                            <td>
                              <img
                                :src="
                                  staticaddr + '/ivgcs/img/epic-play-icon.png'
                                "
                              />
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- END OF LIST VIEW -->
      </div>
      <div class="container" v-show="current_view == 'settings'">
        <!-- future settings view -->
      </div>
    </div>
    <!-- end: container -->
    <!-- Modal -->
    <div
      class="modal"
      id="highFiveResponseModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div
            style="
              position: absolute;
              height: 100%;
              width: 100%;
              overflow: hidden;
            "
          >
            <div
              class="hands"
              style="
                position: relative;
                top: -50vh;
                left: -50vw;
                height: 200vh;
                width: 200vw;
                transform: rotate(-20deg);
              "
            ></div>
          </div>
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Your Video Message to your High Fives</h4>
          </div>
          <div class="modal-body" v-if="orgHighFiveMessage">
            <video
              :src="
                serveraddr +
                '/shield/mercury/file/' +
                orgHighFiveMessage.video +
                '/'
              "
              style="display: block; margin: 0 auto; width: 80%"
              controls
            />
            <h4 class="text-center" style="margin-top: 20px">Text message</h4>
            <div class="well">
              {{ orgHighFiveMessage.message }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end highFiveResponseModal -->
    <div
      class="modal"
      id="studentInformationModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div
            style="
              position: absolute;
              height: 100%;
              width: 100%;
              overflow: hidden;
            "
          >
            <div
              :class="{ hearts: !highFives, hands: highFives }"
              style="
                position: relative;
                top: -50vh;
                left: -50vw;
                height: 200vh;
                width: 200vw;
                transform: rotate(-20deg);
              "
            ></div>
          </div>
          <div class="modal-body" v-if="selectedStudent">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div
              v-if="selectedStudent.video_qad == false"
              class="well text-center"
            >
              This student has yet to submit a video. Encourage them to engage
              with your video reply!
            </div>
            <video
              v-else
              :src="selectedStudent.ivVideo"
              style="display: block; margin: 0 auto; width: 80%"
              controls
            />
            <div
              style="
                width: 80%;
                margin: auto;
                background-color: #fff;
                padding: 15px;
              "
            >
              <button
                v-if="!selectedStudent.response"
                class="btn btn-orange"
                data-dismiss="modal"
                style="float: right"
                v-on:click="showEpicModal(selectedStudent)"
              >
                Send a Video Reply
                {{ selectedStudent ? "to " + selectedStudent.firstName : "" }}
              </button>
              <h4 style="display: inline">Student Information:</h4>
              <br /><b>Last Name:</b> {{ selectedStudent.lastName }} <br /><b
                >First Name:</b
              >
              {{ selectedStudent.firstName }} <br /><b>High School:</b>
              {{ selectedStudent.hsName }} <br /><b>High School City:</b>
              {{ selectedStudent.hsCity }}, {{ selectedStudent.hsState }}
              <h4>Academic information</h4>
              <b>Grade:</b>
              {{
                selectedStudent.grades in filterObject.grades
                  ? filterObject.grades[selectedStudent.grades].label
                  : ""
              }}
              <br /><b>Academic Interests:</b>
              {{
                (selectedStudent.interests ? selectedStudent.interests : [])
                  .filter((i) => i in filterObject.academicInterests)
                  .map((i) => filterObject.academicInterests[i].label)
                  .join(", ")
              }}
              <br /><b>Campus Size:</b>
              {{
                (selectedStudent.sizes ? selectedStudent.sizes : [])
                  .filter((i) => i in filterObject.collegeSize)
                  .map((i) => filterObject.collegeSize[i].label)
                  .join(", ")
              }}
              <br /><b>Geographic Preferences:</b>
              {{
                (selectedStudent.geographies ? selectedStudent.geographies : [])
                  .filter((i) => i in filterObject.geographicPreference)
                  .map((i) => filterObject.geographicPreference[i].label)
                  .join(", ")
              }}
            </div>
            <div
              v-if="
                selectedStudent.response && 'video' in selectedStudent.response
              "
            >
              <h4 class="text-center">Response:</h4>
              <div v-if="!selectedStudent.response.expired">
                <video
                  :src="
                    serveraddr +
                    '/shield/mercury/file/' +
                    selectedStudent.response.video +
                    '/'
                  "
                  style="display: block; margin: 0 auto; width: 80%"
                  controls
                />
                <div
                  style="
                    width: 80%;
                    margin: auto;
                    background-color: #fff;
                    padding: 15px;
                  "
                  class="text-center"
                >
                  The student is not able to see this video 14 days after
                  {{ dateFormat(selectedStudent.response.date) }} but it remains
                  visible here.
                </div>
              </div>
              <div
                v-else
                style="
                  width: 80%;
                  margin: auto;
                  background-color: #fff;
                  padding: 15px;
                "
                class="text-center"
              >
                The video was automatically deleted after 14 days.
              </div>
              <div class="well">{{ selectedStudent.response.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end studentInformationModal -->

    <div
      class="modal"
      id="epicModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div
            style="
              position: absolute;
              height: 100%;
              width: 100%;
              overflow: hidden;
            "
          >
            <div
              :class="{ hearts: !highFives, hands: highFives }"
              style="
                position: relative;
                top: -50vh;
                left: -50vw;
                height: 200vh;
                width: 200vw;
                transform: rotate(-20deg);
              "
            ></div>
          </div>
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title" id="myModalLabel">
              Video Message to
              {{ student_name ? student_name : " your High Fives" }}
            </h4>
          </div>
          <div class="modal-body">
            <VideoResponse
              :highFives="highFives"
              :selectedStudent="selectedStudent"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- end epicModal -->
    <div
      class="modal fade"
      id="needHelp"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">We're here to help!</h4>
          </div>
          <div class="modal-body">
            <p>
              If you're having any trouble, write us a message below or email us
              at amelia@initialview.com!
            </p>
            <div class="form-group">
              <textarea
                class="form-control"
                v-model="supportMessage"
                rows="3"
                placeholder="For example: The interview for Suzie Suzeman is not loading (her email is suzie.suzemeister@gmail.com). I’ll also email you a screenshot of the issue. I am using Chrome Version 62 on a Mac."
              ></textarea>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <button
                  class="btn btn-success pull-right"
                  v-bind:disabled="!supportMessage"
                  v-on:click="submitListSupport()"
                >
                  Submit
                </button>
              </div>
            </div>
            <div v-if="supportMessageSent" class="row">
              <div class="col-xs-12">
                We've received your message! We’re monitoring these messages 24
                hours a day. We’ll be in touch soon!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end needHelp -->
    <nav
      class="navbar navbar-default navbar-fixed-bottom navbar-bottom"
      role="navigation"
    >
      <div class="container text-center">
        <p class="col-xs-12 navbar-text">
          <span>&copy; InitialView {{ currentYear }}</span
          ><span style="margin: 0 10px 0 10px">|</span>
          <a
            href="#"
            data-toggle="modal"
            data-target="#needHelp"
            style="color: #777"
          >
            <i class="glyphicon glyphicon-question-sign"></i>
            Having trouble with anything?
          </a>
        </p>
      </div>
    </nav>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import ivbus from "@/utils/ivbus.js";

require("@/utils/LoginRequired.js");

import {
  ivModalMixin,
  ivAlertMixin,
  ivSpinnerMixin,
  ivUserInfoMixin,
  ivChunkUploadMixin,
  getParameterByName,
} from "@/mixins/base-components-pack.js";

import { orgSupportMixin } from "@/mixins/org-support-mixin-pack.js";

import apiClient from "../utils/apiClient.js";

import moment from "moment-timezone";

import Alert from "@/components/Alert.vue";
import Spinner from "@/components/Spinner.vue";
import VideoResponse from "@/components/VideoResponse.vue";

export default {
  mixins: [
    ivModalMixin,
    ivAlertMixin,
    ivSpinnerMixin,
    ivUserInfoMixin,
    orgSupportMixin,
    ivChunkUploadMixin,
  ],

  props: ["staticaddr", "serveraddr"],

  components: {
    Alert,
    Spinner,
    VideoResponse,
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      // navigation
      current_view: "list",

      highFives: false,
      orgHighFiveMessageRecorded: null,
      orgHighFiveMessage: null,

      supportMessage: "",
      supportEmail: "",
      supportMessageSent: "",

      // org data
      orgName: "",

      colWidths: {},

      selectedStudent: null,
      student_name: "",

      interviews: [],

      // filter fields
      filterObject: {
        dateReceived: "",
        lastName: "",
        firstName: "",
        dateOfBirth: "",
        email: "",
        cell: "",
        citystate: "",

        // Must use a list for grades in order to guarantee correct order
        // See line 305 in amelia/EpicProfile.vue
        // Perhaps use Map because it preserves insertion order
        //~ grades: new Map([
        grades: {
          A: { label: "All As", show: false },
          Ab: { label: "Mostly As and some Bs", show: false },
          aB: { label: "Some As and mostly Bs", show: false },
          Bc: { label: "Mostly Bs and some Cs", show: false },
          C: { label: "Mostly Cs", show: false },
          X: { label: "Mostly below Cs", show: false },
          NA: { label: "I’d prefer not to share", show: false },
          //~ ]),
        },
        // See line 579 in amelia/EpicProfile.vue
        academicInterests: {
          business: { label: "Business and Finance", show: false },
          "cs-math": {
            label: "Computer Science, Math, and Engineering",
            show: false,
          },
          lang: { label: "Foreign Languages", show: false },
          hum: {
            label: "Humanities (Literature, Geography, History, etc.)",
            show: false,
          },
          "lab-sci": {
            label: "Lab Sciences (Bio, Chem, Physics, etc.)",
            show: false,
          },
          med: { label: "Nursing and Medicine", show: false },
          "perf-art": {
            label: "Performing Arts (Dance, Drama, etc.)",
            show: false,
          },
          "soc-sci": {
            label: "Social Sciences (Economics, Political Science, etc.)",
            show: false,
          },
          "vis-art": {
            label: "Visual Arts (Drawing, Painting, etc.)",
            show: false,
          },
          none: { label: "I don’t know yet", show: false },
        },
        // See line 579 in amelia/EpicProfile.vue
        collegeSize: {
          s: {
            label: "3,500 students or less (small college/university)",
            show: false,
          },
          m: {
            label: "3,500 - 10,000 students (mid-sized university)",
            show: false,
          },
          l: { label: "Over 10,000 students (large university)", show: false },
          x: { label: "No strong preference", show: false },
        },
        // See line 579 in amelia/EpicProfile.vue
        geographicPreference: {
          "West Coast": { label: "West Coast", show: false },
          Midwest: { label: "Midwest", show: false },
          "East Coast": { label: "East Coast", show: false },
          "Big city": { label: "Big city", show: false },
          "Metropolitan area": { label: "Metropolitan area", show: false },
          "Small town or College town": {
            label: "Small town or College town",
            show: false,
          },
          //"Rural area": {label:"Rural area", show: false},
          none: { label: "No strong preference", show: false },
        },
        //male, female, other, not-responded
        genders: {
          Female: { label: "Female", show: false },
          Male: { label: "Male", show: false },
          Other: { label: "Other", show: false },
          none: { label: "No Response", show: false },
        },
        //Fall 2020,Spring 2021,Fall 2021
        entryDates: {
          "spring-2023": { label: "Spring 2023", show: false },
          "fall-2023": { label: "Fall 2023", show: false },
          "spring-2024": { label: "Spring 2024", show: false },
          "fall-2024": { label: "Fall 2024", show: false },
          "spring-2025": { label: "Spring 2025", show: false },
          "fall-2025": { label: "Fall 2025", show: false },
          "spring-2026": { label: "Spring 2026", show: false },
          none: { label: "No Response", show: false },
        },
        demographics: {
          "us-cbo": { label: "Affiliated with US CBO", show: false },
          "us-applicant": { label: "Limit to US Applicants Only", show: false },
        },
      },

      // sort columns
      orderByColumn: "dateReceived",
      orderByColumn2: "",
      sortOrder: -1,
      sortOrder2: 0,

      // virtual scrolling:
      $_startIndex: 0,
      itemContainerStyle: null,
      itemsStyle: null,
      scroll: {
        top: 0,
        bottom: 0,
      },
    };
  }, // end data

  created: function () {
    var that = this;
    location.hash = "";
    location.hash = "list";

    $(window).on("hashchange", function () {
      var new_view = location.hash.slice(1);

      if (["list", "settings"].indexOf(new_view) > -1) {
        that.current_view = new_view;
      } else {
        that.current_view = "list";
      }

      if (that.current_view == "list") {
        window.setTimeout(function () {
          that.cacheColWidths();
        });
      }
    });
  }, //end created

  computed: {
    highFiveCount: function () {
      var that = this;
      // Not the best implementation of sum, but easy to read....
      var count = 0;
      for (var i of that.interviews) {
        if (i.highFive) count++;
      }
      return count;
    },
    showFilters: function () {
      var that = this;

      var show = false;

      var filters = [
        "grades",
        "academicInterests",
        "collegeSize",
        "geographicPreference",
        "genders",
        "entryDates",
      ];
      for (var f of filters) {
        var f_shows = Object.values(that.filterObject[f]).map((v) => v.show);
        // Show if some but not all boxes are checked
        show = show || (f_shows.some((x) => x) && !f_shows.every((x) => x));
      }

      return show;
    },
    filteredInterviews: function () {
      var that = this;
      that.page_num = 1;
      that.lastSelectedRow = null;
      that.lastSelectedRowIndex = null;
      return that.interviews.filter(function (iv) {
        var match = true;
        if (match && that.filterObject.dateReceived) {
          match =
            iv.dateReceived &&
            iv.dateReceived
              .toLowerCase()
              .indexOf(that.filterObject.dateReceived.toLowerCase()) !== -1;
        }
        if (match && that.filterObject.lastName) {
          match =
            iv.lastName &&
            iv.lastName
              .toLowerCase()
              .indexOf(that.filterObject.lastName.toLowerCase()) !== -1;
          if (!match)
            console.log("Interview did not pass last name filter", iv);
        }
        if (match && that.filterObject.firstName) {
          match =
            iv.firstName &&
            iv.firstName
              .toLowerCase()
              .indexOf(that.filterObject.firstName.toLowerCase()) !== -1;
          if (!match)
            console.log("Interview did not pass first name filter", iv);
        }
        if (match && that.filterObject.email) {
          match =
            iv.email &&
            iv.email
              .toLowerCase()
              .indexOf(that.filterObject.email.toLowerCase()) !== -1;
          if (!match) console.log("Interview did not pass email filter", iv);
        }
        if (match && that.filterObject.cell) {
          match =
            iv.cell &&
            iv.cell
              .toLowerCase()
              .indexOf(that.filterObject.cell.toLowerCase()) !== -1;
          if (!match) console.log("Interview did not pass cell filter", iv);
        }
        if (match && that.filterObject.citystate) {
          match =
            iv.citystate &&
            iv.citystate
              .toLowerCase()
              .indexOf(that.filterObject.citystate.toLowerCase()) !== -1;
          if (!match)
            console.log("Interview did not pass citystate filter", iv);
        }
        if (match) {
          match =
            !Object.values(that.filterObject.grades).some(
              (grade) => grade.show
            ) || // None are checked
            // Grades match
            (iv.grades &&
              iv.grades in that.filterObject.grades &&
              that.filterObject.grades[iv.grades].show); //||
          // grades not provided
          //  (!iv.grades && that.filterObject.grades.NA.show));
          if (!match) console.log("Interview did not pass grades filter", iv);
        }
        if (match) {
          match =
            !Object.values(that.filterObject.academicInterests).some(
              (interest) => interest.show
            ) || // None are checked
            // Interest matches
            (iv.interests &&
              iv.interests.some(
                (interest) =>
                  interest in that.filterObject.academicInterests &&
                  that.filterObject.academicInterests[interest].show
              )); // ||
          // interests not provided
          //  (iv.interests.length == 0 && that.filterObject.academicInterests.none.show));
          if (!match) console.log("Interview did not pass interest filter", iv);
        }
        if (match) {
          match =
            !Object.values(that.filterObject.collegeSize).some(
              (size) => size.show
            ) || // None are checked
            // sizes match
            (iv.sizes &&
              iv.sizes.some(
                (size) =>
                  size in that.filterObject.collegeSize &&
                  that.filterObject.collegeSize[size].show
              )); // ||
          // sizes not provided
          //					         (iv.sizes.length == 0 && that.filterObject.collegeSize.x.show));
          if (!match) console.log("Interview did not pass size filter", iv);
        }
        if (match) {
          match =
            !Object.values(that.filterObject.geographicPreference).some(
              (geography) => geography.show
            ) || // None are checked
            // Geographies match
            (iv.geographies &&
              iv.geographies.some(
                (geography) =>
                  geography in that.filterObject.geographicPreference &&
                  that.filterObject.geographicPreference[geography].show
              )); //  ||
          // geographies not provided
          //					         (iv.geographies.length == 0 && that.filterObject.geographicPreference["No strong preference"].show));
          if (!match)
            console.log("Interview did not pass geography filter", iv);
        }
        if (match) {
          match =
            !Object.values(that.filterObject.genders).some(
              (gender) => gender.show
            ) || // None are checked
            // Geographies match
            (iv.gender &&
              iv.gender in that.filterObject.genders &&
              that.filterObject.genders[iv.gender].show); //||
          // geographies not provided
          //					         (iv.geographies.length == 0 && that.filterObject.geographicPreference["No strong preference"].show));
          if (!match) console.log("Interview did not pass gender filter", iv);
        }
        if (match) {
          match =
            !Object.values(that.filterObject.entryDates).some(
              (entryDate) => entryDate.show
            ) || // None are checked
            // single value provided
            (iv.collegeEntry &&
              iv.collegeEntry in that.filterObject.entryDates &&
              that.filterObject.entryDates[iv.collegeEntry].show); //||
          // collegeEntry not provided
          //  (!iv.collegeEntry && that.filterObject.collegeEntry.none.show));
          if (!match)
            console.log("Interview did not pass date of entry filter", iv);
        }
        match =
          match &&
          (!that.filterObject.demographics["us-cbo"].show ||
            (that.filterObject.demographics["us-cbo"].show && iv.usCbo));
        match =
          match &&
          (!that.filterObject.demographics["us-applicant"].show ||
            (that.filterObject.demographics["us-applicant"].show &&
              iv.usApplicant));
        if (match) {
          match = iv.highFive == that.highFives;
          if (!match)
            console.log("Interview did not pass High Five filter", iv);
        }
        return match;
      });
    },
    sortedInterviews: function () {
      var that = this;
      return this.filteredInterviews.slice().sort(function (a, b) {
        var aVal = a[that.orderByColumn] || "";
        var bVal = b[that.orderByColumn] || "";
        var a2Val = that.orderByColumn2 ? a[that.orderByColumn2] || "" : "";
        var b2Val = that.orderByColumn2 ? b[that.orderByColumn2] || "" : "";
        var so = that.sortOrder;
        var so2 = that.sortOrder2;
        return aVal > bVal
          ? so
          : aVal < bVal
          ? -1 * so
          : a2Val > b2Val
          ? so2
          : a2Val < b2Val
          ? -1 * so2
          : 0;
      });
    },

    visibleInterviews: function () {
      var _this2 = this;

      var l = _this2.sortedInterviews.length;
      var itemHeight = 44;
      var containerHeight = void 0,
        offsetTop = void 0;

      var scroll = this.scroll;

      if (scroll) {
        var startIndex = -1;
        var endIndex = -1;

        var buffer = 200;
        var poolSize = 2000;
        var scrollTop = ~~(scroll.top / poolSize) * poolSize - buffer;
        var scrollBottom =
          Math.ceil(scroll.bottom / poolSize) * poolSize + buffer;

        // if ((scrollTop === _this2.$_oldScrollTop && scrollBottom === _this2.$_oldScrollBottom) || _this2.$_skip) {
        //   _this2.$_skip = false;
        //   return;
        // } else {
        _this2.$_oldScrollTop = scrollTop;
        _this2.$_oldScrollBottom = scrollBottom;
        // }

        // Fixed height mode
        startIndex = ~~(scrollTop / itemHeight);
        endIndex = Math.ceil(scrollBottom / itemHeight);

        // Bounds
        startIndex < 0 && (startIndex = 0);
        startIndex > l && (startIndex = l);
        endIndex > l && (endIndex = l);

        offsetTop = startIndex * itemHeight;
        containerHeight = l * itemHeight;

        if (
          _this2.$_startIndex !== startIndex ||
          _this2.$_endIndex !== endIndex ||
          _this2.$_offsetTop !== offsetTop ||
          _this2.$_height !== containerHeight ||
          _this2.$_length !== l
        ) {
          _this2.keysEnabled = !(
            startIndex > _this2.$_endIndex || endIndex < _this2.$_startIndex
          );

          //STP: I uncommented this out... It might be essential for virtual scrolling
          Vue.set(_this2, "itemContainerStyle", {
            height: containerHeight + "px",
          });
          Vue.set(_this2, "itemsStyle", {
            marginTop: offsetTop + "px",
          });

          _this2.$_startIndex = startIndex;
          _this2.$_endIndex = endIndex;
          _this2.$_length = l;
          _this2.$_offsetTop = offsetTop;
          _this2.$_height = containerHeight;
        }
      }

      window.setTimeout(function () {
        console.log("tooltip timeout");
        $('[data-toggle="tooltip"].review-score').tooltip();
      });

      return this.sortedInterviews.slice(
        _this2.$_startIndex,
        _this2.$_endIndex
      );
    },
    ...mapState(["apiDown"]),
  },

  mounted: function () {
    var that = this;
    // So Epic can update the students after the ao sends a video and unlocks
    // a student's info
    ivbus.$on("refreshStudents", that.fetchInterviews);

    // fetchOrgInfo
    console.log("fetching organization");
    var spinId = that.spinStart();
    apiClient
      .get("/api/user/user_org_info/")
      .then(function (response) {
        that.spinStop(spinId);
        that.set("orgName", response.data.org.org_name);
      })
      .catch(function (error) {
        that.spinStop(spinId);
      });

    $(window).on("load", function () {
      window.setTimeout(function () {
        that.cacheColWidths();
        that.handleScroll();
      });
    });
    $(window).on("resize", that.cacheColWidths);

    $('[data-toggle="tooltip"]').tooltip();

    that.fetchInterviews();
    // Whenever a modal is close, deselect student
    $("#studentInformationModal").on("hide.bs.modal", function (e) {
      that.selectedStudent = null;
      console.log("Deselecting student");
    });
    $("#epicModal").on("hide.bs.modal", function (e) {
      that.selectedStudent = null;
      console.log("Deselecting student");
    });
  },

  methods: {
    headClick(col) {
      var that = this;
      console.log("event target element column", col, this);
      if (that.orderByColumn != col) {
        console.log("Selecting col " + col);
        Vue.set(that, "orderBycolumn", col);
        //Vue.set(that.orderBycolumn = col
      } else {
        console.log("Switching sort order");
        that.sortOrder *= -1;
      }
    },
    set(name, val) {
      // same as Vue.set
      // prevent undefined data from causing unexpective behavior
      if (val != undefined) {
        Vue.set(this, name, val);
      }
    },

    reset() {
      Object.values(this.filterObject.grades).forEach((g) => (g.show = false));
      Object.values(this.filterObject.academicInterests).forEach(
        (g) => (g.show = false)
      );
      Object.values(this.filterObject.collegeSize).forEach(
        (g) => (g.show = false)
      );
      Object.values(this.filterObject.geographicPreference).forEach(
        (g) => (g.show = false)
      );
      Object.values(this.filterObject.genders).forEach((g) => (g.show = false));
      Object.values(this.filterObject.entryDates).forEach(
        (g) => (g.show = false)
      );
    },

    submitListSupport: function () {
      var that = this;
      var spinId = that.spinStart();
      apiClient
        .post("/api/org/support/", { message: that.supportMessage })
        .then(function (response) {
          that.spinStop(spinId);
          that.supportMessageSent = that.supportMessage;
          that.supportMessage = "";
        });
    },

    handleScroll: function () {
      var el = $("#scrollable")[0];
      var scroll = {
        top: el.scrollTop,
        bottom: el.scrollTop + el.clientHeight,
      };

      if (scroll.bottom >= 0 && scroll.top <= scroll.bottom) {
        Vue.set(this, "scroll", scroll);
      } else {
        Vue.set(this, "scroll", null);
      }
    },

    cacheColWidths: function () {
      var that = this;
      $("#listhead")
        .find("th")
        .each(function () {
          Vue.set(
            that.colWidths,
            $(this).attr("data-ref"),
            $(this).outerWidth() + "px"
          );
        });
    },

    dateFormat: function (date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },

    // This function is called when an AO successfully records a video and
    // sends it. I reset the selected student here
    fetchInterviews: function () {
      var that = this;
      var spinId = this.spinStart();

      apiClient
        .get("/api/interview/admissions/epic/list/")
        .then(function (response) {
          that.spinStop(spinId);
          that.orgHighFiveMessageRecorded = Boolean(
            response.data.genericResponse
          );
          that.orgHighFiveMessage = response.data.genericResponse;

          Vue.set(that, "interviews", response.data.interviews);
          that.selectedStudent = null;

          that.cacheColWidths();

          Vue.set(
            that,
            "orgHighFiveMessageRecorded",
            response.data.genericResponse
          );
        })
        .catch(function (error) {
          that.spinStop(spinId);
          console.log(error);
        });
    },

    showHighFiveResponseModal: async function (student) {
      var that = this;
      $("#highFiveResponseModal").modal("show");
    },
    showStudentInformationModal: async function (student) {
      var that = this;
      this.selectedStudent = student;
      console.log("Got student", student);
      ivbus.setFlag("send_id", student.send_id);
      ivbus.setFlag("student_name", student.firstName + " " + student.lastName);
      that.student_name = student.firstName + " " + student.lastName; // for use on the Epic modal title after the list (and selectedStudent) have been refreshed
      ivbus.$emit("setEpicCurrentView", "landing");
      $("#studentInformationModal").modal("show");
    },
    showVideoMessageModal: function () {
      var that = this;
      $("#videoMessageModal").modal("show");
      ivbus.$emit("joinRoom");
    },
    showEpicModal: function (student) {
      var that = this;
      $("#studentInformationModal").modal("hide");
      // Hiding the student info modal deselects the student
      // Reselect here
      that.selectedStudent = student;
      $("#epicModal").modal("show");
      ivbus.$emit("joinRoom");
    },
    showHighFiveEpicModal: function () {
      var that = this;
      this.selectedStudent = null;
      ivbus.setFlag("send_id", null);
      ivbus.setFlag("student_name", null);
      ivbus.$emit("setEpicCurrentView", "landing");
      $("#epicModal").modal("show");
      ivbus.$emit("joinRoom");
    },

    // // LIST VIEW HELPERS ////
    exportStudents() {
      this.downloadasTextFile("Students.csv", this.arrayToCSV(this.interviews));
    },
    // See https://stackoverflow.com/a/46948292
    arrayToCSV(array) {
      var that = this;
      var includedKeys = {
        lastName: "Last Name",
        firstName: "First Name",
        email: "Email Address",
        cell: "Cellphone Number",
        gender: "Gender",
        collegeEntry: "Date of College Entry",
        hsName: "High School Name",
        hsCity: "High School City",
        hsState: "High School State",
        grades: "Self-reported Average Grades",
        interests: "Academic Interests",
        sizes: "College Size Preferences",
        geographies: "Geographic Preferences",
        highFive: "High Five",
      };

      let str =
        `${Object.values(includedKeys)
          .map((k) => `"${k}"`)
          .join(",")}` + "\r\n";
      function formatter(key, value) {
        console.log("working with key value", key, value);
        if (key == "interests") {
          return `"${value
            .map((v) => that.filterObject.academicInterests[v].label || "")
            .join(";")}"`;
        }
        if (key == "sizes") {
          return `"${value
            .map((v) => that.filterObject.collegeSize[v].label || "")
            .join(";")}"`;
        }
        if (key == "geographies") {
          // The keys of geographies are good enough
          // I also got a 'rural area' key, which I didn't know existed
          return `"${value
            .map((v) =>
              v in that.filterObject.geographicPreference
                ? that.filterObject.geographicPreference[v].label
                : v
            )
            .join(";")}"`;
          //~ return `"${value.join(';')}"`
        }
        return `"${value}"`;
      }
      return array.reduce((str, next) => {
        str +=
          `${Object.keys(includedKeys)
            .map((k) => formatter(k, next[k]))
            .join(",")}` + "\r\n";
        return str;
      }, str);
    },
    // See https://phpcoder.tech/wp-content/cache/all/create-dynamically-generated-text-file-and-download-using-javascript/index.html
    downloadasTextFile(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },

    user_logout: function () {
      var that = this;
      var spinId = that.spinStart();
      apiClient
        .get("/api/user/sign_out/")
        .then(function (response) {
          that.spinStop(spinId);
          sessionStorage.removeItem("apitoken");
          delete apiClient.defaults.headers.common["Authorization"];
          ivbus.setFlag("user", null);
          that.fetchUser();
          window.location = that.serveraddr + "/login";
        })
        .catch(function () {
          that.spinStop(spinId);
          sessionStorage.removeItem("apitoken");
          delete apiClient.defaults.headers.common["Authorization"];
          ivbus.setFlag("user", null);
          window.location = that.serveraddr + "/login";
        });
    },
  }, // End methods
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  background: white !important;
}
thead {
  background: rgb(0, 0, 0, 0); /* transparent */
  color: black;
}
td {
  overflow: hidden;
}

.sorted-column {
  background: rgb(0, 0, 0, 0); /* transparent */
  color: black;
}

#interviews {
  position: relative;
} /* to make a new stacking context */

#listhead {
  margin-bottom: 0;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #ffe8e8;
}
.table-striped > tbody > tr:nth-of-type(even) {
  background-color: white;
}
.label-default {
  background: #eee;
  color: #333;
}
.panel-title {
  color: #e96724;
}
.modal-dialog {
  min-width: 600px;
}
.modal-content {
  overflow-y: auto;
  max-height: revert !important;
  position: relative; /* to make a new box-sizing context */
}
.modal-header,
.modal-body {
  position: relative; /* to make a new stacking context */
}
.modal-header h4 {
  font-weight: bold;
}
#pool-of-love-container {
  background: none padding;
}
[role="tab"] {
  cursor: pointer;
}
#filter-displays > span {
  display: inline-block;
  padding: 5px;
  margin: 5px;
}
#filter-displays > span > span {
  padding: 5px;
  cursor: pointer;
}
#highFiveLock {
  position: absolute;
  min-height: 300px;
  height: 100%;
  width: 100%;
  text-align: center;
  background: #ffa50033;
  padding-top: 100px;
  border: solid 4px #e96724;
  /*background: repeat url("../assets/locked.svg");
	opacity: 50%;*/
}
#highFiveLock button {
  font-weight: bold;
}
#list-view {
  height: calc(100vh - 370px);
}

.hearts {
  background: repeat url("../assets/heart_spaced.svg");
}
.hands {
  background: repeat url("../assets/hand_spaced.svg");
}
/* See https://www.sitepoint.com/css3-transform-background-image/ */
/*.hearts {
	overflow: hidden;
}
.hearts::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
  background: url(../assets/heart_spaced.svg) 0 0 repeat;
  transform: rotate(-20deg) scale(2);
}
.hands {
	background: repeat url("../assets/hand_spaced.svg")
}
*/

[role="presentation"] {
  background: none;
}
[role="presentation"] > a {
  font-weight: bold;
  color: black;
  font-size: larger;
  background: #f8f8f8;
  background: #dadada;
}
/*[role=presentation].hearts > a {
	background: repeat url("../assets/heart_spaced.svg");
}
[role=presentation].hands > a {
	background: repeat url("../assets/hand_spaced.svg");
}*/
[role="presentation"].active > a {
  color: #e96724 !important;
  background: white;
}
button.close {
  opacity: 1;
}
span.btn-link {
  padding: 3px 5px;
}
#tabs {
  margin: 0 -15px;
  background: #dadada;
  background: #f8f8f8;
  padding: 10px 0 0 30px;
}
.table {
  margin-bottom: 0px;
}
[data-toggle="collapse"]:before {
  color: #e96724;
}
[data-toggle="collapse"]:before {
  display: inline-block;
  /*font: normal normal normal 14px/1 FontAwesome;*/
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f054";
  content: ">";
  transform: rotate(-90deg);
  transition: all linear 0.25s;
}
[data-toggle="collapse"].collapsed:before {
  transform: rotate(90deg);
}
</style>
